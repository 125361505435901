import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./CSS/TopUp.css";

const TopUp = () => {
    const navigate = useNavigate();

    const handlePaymentOption = (paymentMethod) => {
        if (paymentMethod === 'paystack') {
            // Redirect to Paystack page using navigate
            navigate('/paystack-payment-url');
        } else if (paymentMethod === 'stripe') {
            // Redirect to Stripe page using navigate
            navigate('/stripe-payment-url');
        }
    };

    return (
        <div className="topup-container">
            <h2>Select Payment Option</h2>
            <div className="payment-options">
                <button onClick={() => handlePaymentOption('paystack')}>Pay with Paystack</button>
                <button onClick={() => handlePaymentOption('stripe')}>Pay with Stripe</button>
            </div>
        </div>
    );
};

export default TopUp;
