import React, { createContext, useEffect, useState } from "react";
import { backend_url } from "../App";

export const ShopContext = createContext(null);
const environment = process.env.REACT_APP_ENVIRONMENT || "staging";

const ShopContextProvider = (props) => {
  const [products, setProducts] = useState([]);
  const delivery_fee = 0; // Replace with actual delivery fee logic
  const [showSearch, setShowSearch] = useState(false);

  const getDefaultCart = () => {
    let cart = {};
    for (let i = 0; i < 300; i++) {
      cart[i] = 0;
    }
    return cart;
  };

  const [cartItems, setCartItems] = useState(getDefaultCart());

  useEffect(() => {
    // Fetch all products
    fetch(`${backend_url}/product/allproducts`)
      .then((res) => {
        if (!res.ok) {
          return res.text().then(text => { throw new Error(`HTTP error! status: ${res.status}, body: ${text}`); });
        }
        return res.json();
      })
      .then((data) => {
        setProducts(data.payload.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });

    // Fetch cart items if authenticated
    if (localStorage.getItem("auth-token")) {
      fetch(`${backend_url}/getcart`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'auth-token': `${localStorage.getItem("auth-token")}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      })
        .then((resp) => {
          if (!resp.ok) {
            return resp.text().then(text => { throw new Error(`HTTP error! status: ${resp.status}, body: ${text}`); });
          }
          return resp.json();
        })
        .then((data) => {
          console.log("Cart Response:", data);
          setCartItems(data);
        })
        .catch((error) => {
          console.error("Error fetching cart:", error);
        });
    }
  }, []);

  const getCartAmount = () => {
    let totalAmount = 0;
    for (const item in cartItems) {
      if (cartItems[item] > 0) {
        let itemInfo = products.find((product) => product.id === Number(item));
        if (itemInfo) {
          totalAmount += cartItems[item] * itemInfo.new_price;
        }
      }
    }
    return totalAmount;
  };

  const getTotalCartAmount = () => {
    return getCartAmount(); 
  };

  const getItemCount = () => {
    return Object.values(cartItems).reduce((acc, count) => acc + count, 0);
  };

  const addToCart = (itemId) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    fetch(`${backend_url}/addtocart`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'auth-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "itemId": itemId }),
    }).catch((error) => console.error("Error adding to cart:", error));
  };

  const removeFromCart = (itemId) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    setCartItems((prev) => {
      const newCount = prev[itemId] - 1;
      return { ...prev, [itemId]: Math.max(newCount, 0) };
    });
    fetch(`${backend_url}/removefromcart`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'auth-token': `${localStorage.getItem("auth-token")}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "itemId": itemId }),
    }).catch((error) => console.error("Error removing from cart:", error));
  };

  const updateQuantity = (itemId, quantity) => {
    if (!localStorage.getItem("auth-token")) {
      alert("Please Login");
      return;
    }
    if (quantity <= 0) {
      removeFromCart(itemId);
    } else {
      setCartItems((prev) => ({ ...prev, [itemId]: quantity }));
      fetch(`${backend_url}/updatecart`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'auth-token': `${localStorage.getItem("auth-token")}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "itemId": itemId, "quantity": quantity }),
      }).catch((error) => console.error("Error updating cart:", error));
    }
  };

  const contextValue = {
    environment,
    products,
    cartItems,
    delivery_fee,
    getCartAmount,
    getItemCount,
    addToCart,
    removeFromCart,
    updateQuantity,
    getTotalCartAmount,
    showSearch,
    setShowSearch,
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
