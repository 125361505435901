import React, { useEffect, useContext, useState } from 'react';
import '../../src/Pages/CSS/Orders.css';
import { ShopContext } from "../Context/ShopContextProvider";

const Orders = () => {
  const { environment, cartItems } = useContext(ShopContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BaseApi_URL}/orders`, {
          method: 'GET',
          headers: {
            'auth-token': `${localStorage.getItem("auth-token")}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Failed to fetch orders');

        const data = await response.json();
        setOrders(data.payload);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  return (
    <div className="orders-container">
      <h1>Your Orders</h1>
      {loading ? (
        <p>Loading orders...</p>
      ) : (
        <div className="orders-list">
          {orders.length === 0 ? (
            <p>No orders found.</p>
          ) : (
            orders.map((order) => (
              <div className="order-card" key={order.id}>
                <h2>Order #{order.id}</h2>
                <p>Date: {new Date(order.createdAt).toLocaleDateString()}</p>
                <p>Status: <span className={`status ${order.status.toLowerCase()}`}>{order.status}</span></p>
                <div className="order-items">
                  <h3>Items:</h3>
                  {order.items.map((item) => (
                    <div className="order-item" key={item.id}>
                      <img src={item.image} alt={item.name} />
                      <div className="item-details">
                        <h4>{item.name}</h4>
                        <p>Price: {item.price}</p>
                        <p>Quantity: {item.quantity}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <p>Total: {order.total}</p>
                <button className="view-receipt">View Receipt</button>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
