import React, { useState, useEffect } from 'react';
import { assets } from '../Components/Assets/assets';
import bannerImage from '../../src/Components/Assets/banner_kids.png';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import skeleton styles

const Contact = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading time (e.g., fetching contact details)
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {/* Banner Image */}
      <div className="banner-container">
        <img src={bannerImage} alt="Banner" className="banner-image" />
      </div>
      
      <div className='text-center text-2xl pt-10 border-t'>
        {loading ? (
          <Skeleton height={40} width={300} />
        ) : (
          <p>Contact Us</p> // Replace with your actual content
        )}
      </div>

      <div className='my-10 flex flex-col justify-center md:flex-row gap-10 mb-28'>
        <img className='w-full md:max-w-[480px]' src={assets.contact_img} alt="" />
        <div className='flex flex-col justify-center items-start gap-6'>
          {loading ? (
            <>
              <Skeleton height={30} width={200} />
              <Skeleton height={20} width={300} />
              <Skeleton height={20} width={300} />
              <Skeleton height={30} width={200} />
              <Skeleton height={20} width={300} />
              <Skeleton height={40} width={150} />
            </>
          ) : (
            <>
              <p className='font-semibold text-xl text-gray-600'>Our Store</p>
              <p className='text-gray-500'>54709 Willms Station <br /> Suite 350, Washington, USA</p>
              <p className='text-gray-500'>Tel: (415) 555-0132 <br /> Email: admin@forever.com</p>
              <p className='font-semibold text-xl text-gray-600'>Careers at Forever</p>
              <p className='text-gray-500'>Learn more about our teams and job openings.</p>
              <button className='border border-black px-8 py-4 text-sm hover:bg-black hover:text-white transition-all duration-500'>Explore Jobs</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Contact;
