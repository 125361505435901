
import React from 'react';
import './CSS/Modal.css';

const Modal = ({ isOpen, onClose, onConfirm, amount, setAmount }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Withdraw Funds</h2>
                <label htmlFor="amount">Enter Amount:</label>
                <input
                    type="number"
                    id="amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="₦0.00"
                />
                <div className="modal-actions">
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={onConfirm}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
