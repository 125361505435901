import React, { useState } from 'react';
import vtpassService from '../api/vtpassService'; // Adjust path as necessary

const TestVTpass = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [networkProvider, setNetworkProvider] = useState('mtn'); // Default network provider
  const [dataPlan, setDataPlan] = useState('');
  const [result, setResult] = useState('');

  // Basic input validation
  const isValidInput = () => {
    if (!phoneNumber || !amount) {
      setResult("Phone number and amount are required.");
      return false;
    }
    return true;
  };

  // Handle Airtime Recharge
// Improved error logging in rechargeAirtime
const handleRechargeAirtime = async () => {
  if (!isValidInput()) return;

  try {
    const response = await vtpassService.rechargeAirtime(networkProvider, phoneNumber, amount);
    setResult(`Airtime Recharge Successful: Transaction ID: ${response.content?.transactionId || 'N/A'}, Status: ${response.response_description}`);
  } catch (error) {
    console.error("Recharge Airtime Error:", error); // Log the entire error object for debugging
    setResult(`Error: ${error.response?.data?.response_description || error.message || "An unknown error occurred."}`);
  }
};


  // Handle Data Subscription
  const handleSubscribeData = async () => {
    if (!isValidInput() || !dataPlan) {
      setResult("Phone number, amount, and data plan are required.");
      return;
    }

    try {
      const response = await vtpassService.subscribeData(networkProvider, phoneNumber, amount, dataPlan);
      setResult(`Data Subscription Successful: Transaction ID: ${response.content?.transactionId || 'N/A'}, Status: ${response.response_description}`);
    } catch (error) {
      setResult(`Error: ${error.response?.data?.response_description || error.message}`);
    }
  };

  return (
    <div>
      <h2>Test VTpass API</h2>

      {/* Select Network Provider */}
      <label>Network Provider:</label>
      <select value={networkProvider} onChange={(e) => setNetworkProvider(e.target.value)}>
        <option value="mtn">MTN</option>
        <option value="glo">GLO</option>
        <option value="airtel">Airtel</option>
        <option value="etisalat">9mobile</option>
      </select>

      {/* Input for Phone Number */}
      <label>Phone Number:</label>
      <input
        type="tel"
        placeholder="Enter Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />

      {/* Input for Amount */}
      <label>Amount (NGN):</label>
      <input
        type="number"
        placeholder="Enter Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />

      {/* Airtime Recharge Button */}
      <button onClick={handleRechargeAirtime}>Recharge Airtime</button>

      {/* Input for Data Plan */}
      <label>Data Plan (e.g., 1GB):</label>
      <input
        type="text"
        placeholder="Enter Data Plan"
        value={dataPlan}
        onChange={(e) => setDataPlan(e.target.value)}
      />

      {/* Data Subscription Button */}
      <button onClick={handleSubscribeData}>Subscribe Data</button>

      {/* Result Display */}
      <div>
        <h3>Result:</h3>
        <p>{result}</p>
      </div>
    </div>
  );
};

export default TestVTpass;
