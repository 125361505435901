import React, { useEffect, useState } from 'react';
import "./CSS/Wallet.css";
import { useNavigate } from 'react-router-dom';
import Modal from './Modal'; // Assuming you have a Modal component for confirmation
import RefundModal from './RefundModal';


const WalletBalance = () => {
    const [walletBalance, setWalletBalance] = useState(0.0);
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchWalletData = async () => {
            try {
                const balanceResponse = await fetch('/api/wallet/balance');
                const historyResponse = await fetch('/api/wallet/history');
                
                if (!balanceResponse.ok || !historyResponse.ok) {
                    throw new Error('Failed to fetch wallet data');
                }

                const balanceData = await balanceResponse.json();
                const historyData = await historyResponse.json();

                setWalletBalance(balanceData.wallet_balance);
                setTransactionHistory(historyData.transactions);
            } catch (error) {
                console.error('Error fetching wallet data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchWalletData();
    }, []);

    const handleWithdrawFunds = async () => {
        console.log("Withdrawn Amount:", withdrawAmount);
        setIsWithdrawModalOpen(false);
    };

    const handleRefundRequest = async () => {
        console.log("Refund requested for transaction:", selectedTransaction);
        setIsRefundModalOpen(false);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="wallet-balance-container">
            <h2>Wallet Balance</h2>
            <p>Your current balance: ₦{walletBalance.toFixed(2)}</p>

            <div className="wallet-actions">
                <button onClick={() => navigate('/addaccount')}>
                    Add New Account or Wallet
                </button>
                <button onClick={() => navigate('/topup')}>Top-Up</button>
                <button onClick={() => navigate('/transfer')}>Transfer</button>
                <button onClick={() => setIsWithdrawModalOpen(true)}>Withdraw Funds</button>
            </div>

            <h4>Transaction History</h4>
            <ul className="transaction-history">
                {transactionHistory.map((transaction, index) => (
                    <li key={index}>
                        <span>{new Date(transaction.date).toLocaleDateString()}</span>
                        <span>{transaction.description}</span>
                        <span>₦{Math.abs(transaction.amount).toFixed(2)}</span>
                    </li>
                ))}
            </ul>

            {/* Modal for withdrawing funds */}
            <Modal
                isOpen={isWithdrawModalOpen}
                onClose={() => setIsWithdrawModalOpen(false)}
                onConfirm={handleWithdrawFunds}
                amount={withdrawAmount}
                setAmount={setWithdrawAmount}
            />

            {/* Modal for requesting a refund */}
            <RefundModal
                isOpen={isRefundModalOpen}
                onClose={() => setIsRefundModalOpen(false)}
                transaction={selectedTransaction}
                onRequestRefund={handleRefundRequest}
            />
        </div>
    );
};

export default WalletBalance;
