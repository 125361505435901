import React, { useContext, useRef, useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/logo_big.png";
import cart_icon from "../Assets/cart_icon.png";
import { ShopContext } from "../../Context/ShopContextProvider";
import nav_dropdown from "../Assets/nav_dropdown.png";
import { assets } from "../Assets/assets";
import { FaBell } from 'react-icons/fa';

const Navbar = () => {
  const [menu, setMenu] = useState("shop");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notifications] = useState([
    { id: 1, message: "Top-up successful.", isRead: true },
    { id: 2, message: "Withdrawal of ₦2,000 completed.", isRead: false },
    { id: 3, message: "Transfer of ₦5,000 successful.", isRead: false },
    { id: 4, message: "Order #1234 has been shipped.", isRead: true },
    { id: 5, message: "You have a new message from support.", isRead: false },
  ]);
  
  const { setShowSearch, getItemCount, setToken, setCartItems } = useContext(ShopContext);
  const navigate = useNavigate(); 
  const menuRef = useRef();
  const token = localStorage.getItem("auth-token");

  const dropdown_toggle = (e) => {
    menuRef.current.classList.toggle("nav-menu-visible");
    e.currentTarget.classList.toggle("open");
  };

  const logout = () => {
    localStorage.removeItem("auth-token");
    setToken(null);
    setCartItems({});
    navigate("/login");
  };

  const handleProfileClick = () => {
    setDropdownVisible((prev) => !prev);
  };

  // Redirect to notification page when the bell is clicked
  const handleNotificationClick = () => {
    navigate("/notification");
  };

  // Count unread notifications
  const unreadCount = notifications.filter((notif) => !notif.isRead).length;

  return (
    <div className="nav">
      <Link
        to="/"
        onClick={() => setMenu("shop")}
        className="nav-logo"
        style={{ textDecoration: "none" }}
      >
        <img src={logo} alt="logo" className="w-12 h-12" />
        <p>MBOK CONNECT</p>
      </Link>

      {/* Dropdown icon for small screens */}
      <img onClick={dropdown_toggle} className='nav-dropdown' src={nav_dropdown} alt="menu dropdown" />
      <ul ref={menuRef} className="nav-menu">
        <li onClick={() => setMenu("shop")}>
          <Link to="/" style={{ textDecoration: "none" }}>Home</Link>
          {menu === "shop" && <hr />}
        </li>
        <li onClick={() => setMenu("mens")}>
          <Link to="/mens" style={{ textDecoration: "none" }}>Collections</Link>
          {menu === "mens" && <hr />}
        </li>
        <li onClick={() => setMenu("utility")}>
          <Link to="/utility-services" style={{ textDecoration: "none" }}>Utility Bills</Link>
          {menu === "utility" && <hr />}
        </li>
        <li onClick={() => setMenu("contact")}>
          <Link to="/contact" style={{ textDecoration: "none" }}>Contact</Link>
          {menu === "contact" && <hr />}
        </li>
        <li onClick={() => setMenu("about")}>
          <Link to="/about" style={{ textDecoration: "none" }}>About</Link>
          {menu === "about" && <hr />}
        </li>
      </ul>

      {/* Icons in a single row */}
      <div className="nav-icons">
        <div className="search-icon-wrapper">
          <img
            onClick={() => {
              setShowSearch(true); // Trigger showing the search bar
              navigate("/mens"); // Navigate to the search page (if needed)
            }}
            src={assets.search_icon}
            className="icon cursor-pointer"
            alt="Search"
          />
        </div>

        {/* Notification Bell */}
        <div className="notification-bell" onClick={handleNotificationClick}>
          <FaBell size={24} className="icon notification-bell-icon" />
          {unreadCount > 0 && (
            <span className="notification-count">{unreadCount}</span>
          )}
        </div>

        {token ? (
          <div className="group relative" onMouseLeave={() => setDropdownVisible(false)}>
            <img
              onClick={handleProfileClick}
              className="icon cursor-pointer"
              src={assets.profile_icon}
              alt="Profile Icon"
            />
            {/* Dropdown Menu */}
            {dropdownVisible && (
              <div className="dropdown-menu">
                <Link to="/profile" className="dropdown-item">My Profile</Link>
                <Link to="/wallet" className="dropdown-item">Wallet</Link>
                <Link to="/orders" className="dropdown-item">Orders</Link>
                <span onClick={logout} className="dropdown-item">Logout</span>
              </div>
            )}
          </div>
        ) : (
          <Link to="/login" className="icon">
            <img src={assets.profile_icon} className="icon cursor-pointer" alt="Login Icon" />
          </Link>
        )}

        <Link to="/cart" className="relative">
          <img src={cart_icon} className="icon" alt="Cart" />
          {getItemCount() > 0 && (
            <div className="nav-cart-count">
              {getItemCount()}
            </div>
          )}
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
