import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./CSS/Transfer.css";

const Transfer = () => {
    const [recipientEmail, setRecipientEmail] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleTransfer = async () => {
        if (transferAmount <= 0 || !recipientEmail) {
            setMessage('Please enter valid details.');
            return;
        }

        try {
            const response = await fetch('/api/wallet/transfer', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ recipientEmail, amount: transferAmount }),
            });

            const data = await response.json();
            if (response.ok) {
                // Redirect to invoice page after successful transfer
                navigate('/wallet/invoice', { state: { transferDetails: data } });
            } else {
                setMessage(data.error);
            }
        } catch (error) {
            setMessage('Error processing transfer.');
        }
    };

    return (
        <div className="transfer-container">
            <h2>Transfer to Another User</h2>
            <input
                type="email"
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.target.value)}
                placeholder="Recipient Email"
            />
            <input
                type="number"
                value={transferAmount}
                onChange={(e) => setTransferAmount(e.target.value)}
                placeholder="Amount to Transfer"
            />
            <button onClick={handleTransfer}>Submit Transfer</button>

            {message && <p>{message}</p>}
        </div>
    );
};

export default Transfer;
