import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Shop from "./Pages/Shop";
import Cart from "./Pages/Cart";
import Product from "./Pages/Product";
import SearchBar from "./Components/SearchBar/SearchBar";
import LoginSignup from "./Pages/LoginSignup";
import PlaceOrder from "./Pages/PlaceOrder";

// Utility Services Components
import UtilityServices from './Pages/UtilityServices';
import AirtimeRecharge from './Pages/AirtimeRecharge';
import DataSubscription from './Pages/DataSubscription';
import InternetSubscription from './Pages/InternetSubscription';
import CableSubscription from './Pages/CableSubscription';
import ElectricityBillPayment from './Pages/ElectricityBillPayment';
import TestVTpass from "./api/TestVTpass";
import Contact from "./Pages/Contact";
import Mens from "./Pages/Mens";
import About from "./Pages/About";

import { getAppUrls } from './config';
import { PublicRoutes, ProtectedRoutes } from "./Components/AuthGuard";
import Footer from "./Components/Footer/Footer";
import Orders from "./Pages/Orders";
import Profile from "./Pages/Profile";
import Wallet from "./Pages/Wallet";
import TopUp from "./Pages/TopUp";
import Transfer from "./Pages/Transfer";
import Invoice from "./Pages/Invoice";
import Notification from "./Components/Notification/Notification";
import AddAccount from "./Components/AddAccount/AddAccount";

export const backend_url = getAppUrls().url;
export const currency = process.env.REACT_APP_CURRENCY;

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <SearchBar />
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<PublicRoutes />}>
            <Route path="/" element={<Shop gender="all" />} />
            <Route path='/mens' element={<Mens />} />
            <Route path='/product' element={<Product />} />
            <Route path=":productId" element={<Product />} />
            <Route path='/place-order' element={<PlaceOrder />} />
            <Route path="/login" element={<LoginSignup />} />
            <Route path="/utility-services" element={<UtilityServices />} />
            <Route path="/airtime-recharge" element={<AirtimeRecharge />} />
            <Route path="/data-subscription" element={<DataSubscription />} />
            <Route path="/internet-subscription" element={<InternetSubscription />} />
            <Route path="/cable-subscription" element={<CableSubscription />} />
            <Route path="/electricity-bill-payment" element={<ElectricityBillPayment />} />
            <Route path="/test" element={<TestVTpass />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/wallet" element={<Wallet/>} />
            <Route path="/topup" element={<TopUp/>} />
            <Route path="/transfer" element={<Transfer/>} />
            <Route path="/invoice" element={<Invoice/>} />
            <Route path="/notification" element={<Notification/>} />
            <Route path="/addaccount" element={<AddAccount/>} />

            {/* Fallback Route */}
            <Route path="*" element={<h2>404 Not Found</h2>} />
          </Route>

          {/* Protected Routes */}
          <Route element={<ProtectedRoutes />}>
            <Route path="/cart" element={<Cart />} />
          </Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
