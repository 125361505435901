import React, { useState } from 'react';
import vtpassService from '../api/vtpassService';
import './CSS/AirtimeRecharge.css';
import airtimeImage from '../Components/Assets/glo-etisalat-nd-mtn-logo-65021854.gif';

const AirtimeRecharge = () => {
  const [network, setNetwork] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [amount, setAmount] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await vtpassService.rechargeAirtime(phoneNumber, amount);
      alert(`Airtime recharge successful: ${response.message}`);
    } catch (error) {
      alert('Failed to recharge airtime');
    }
  };

  return (
    
    <div className="airtime-page">
       {/* Banner Image */}
       <div className="banner-container">
        <img src={airtimeImage} alt="Banner" className="airtime-image" />
      </div>

   
      <div className="airtime-recharge">
      <h2 className="airtime-header">Airtime Recharge</h2>

      <div className="airtime-container">

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="network">Network:</label>
          <select
            id="network"
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
            required
          >
            <option value="">Select Network</option>
            <option value="MTN">MTN</option>
            <option value="Airtel">Airtel</option>
            <option value="Glo">Glo</option>
            <option value="9mobile">9mobile</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="phone-number">Phone Number:</label>
          <input
            type="tel"
            id="phone-number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="amount">Amount (₦):</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <button type="submit">Recharge Airtime</button>
      </form>
    </div>
    </div>
    </div>
  );
};

export default AirtimeRecharge;
