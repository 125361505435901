import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton"; // Import Skeleton loader
import "react-loading-skeleton/dist/skeleton.css"; // Skeleton styles
import aboutImage from "../Components/Assets/about-post.jpg"; // Import the image
import MetaTags from "../Components/MetaTags";

const AboutUs = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate image loading delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // 2 seconds delay for loader
  }, []);

  const styles = {
    container: {
      padding: "80px 40px",
      backgroundColor: "#f8f8f8",
      borderRadius: "10px",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
      maxWidth: "800px",
      margin: "40px auto",
      fontFamily: "'Arial', sans-serif",
      textAlign: "center",
    },
    heading: {
      fontSize: "2.5rem",
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#333",
    },
    image: {
      maxWidth: "80%",
      height: "auto",
      margin: "20px 0",
      borderRadius: "10px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    },
    paragraph: {
      fontSize: "1.2rem",
      lineHeight: "1.6",
      color: "#555",
      margin: "20px 0",
      padding: "0 20px",
    },
    servicesList: {
      listStyleType: "none",
      padding: 0,
      margin: "20px 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    listItem: {
      fontSize: "1.1rem",
      margin: "10px 0",
      color: "#444",
      padding: "10px 15px",
      backgroundColor: "#fff",
      borderRadius: "5px",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      width: "80%",
      transition: "transform 0.3s",
    },
    listItemHover: {
      transform: "scale(1.05)",
      backgroundColor: "#ff6f61",
      color: "#fff",
    },
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Mbok Connect",
    "url": "https://www.mbokconnect.com",
    "logo": "https://www.mbokconnect.com/assets/Screenshot%20Mbok%20Logo.png",
    "sameAs": [
        "LINK_TO_YOUR_FACEBOOK",
        "LINK_TO_YOUR_TWITTER",
        "https://wwww.instagram.com/mbok_connect?igsh=Y3lscXppM3VtN29u"
    ],
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+123456789",
        "contactType": "Customer Service"
    }
};

const StructuredData = () => (
  <script type="application/ld+json">
      {JSON.stringify(structuredData)}
  </script>
);

<StructuredData /> 

  return (
    <div style={{ padding: "20px" }}>
      <MetaTags
        title="About Mbok Connect"
        description="Learn more about Mbok Connect and our mission to provide seamless utility payment services."
        keywords="about us, Mbok Connect, utility services, Nigeria"
      />

      {/* Skeleton Loader for the entire page */}
      {loading ? (
        <Skeleton height={600} width="100%" />
      ) : (
        <>
          {/* Banner Section */}
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <img
              src={aboutImage}
              alt="About Us"
              style={{
                width: "100%",
                maxHeight: "400px", // Adjust the height as needed
                objectFit: "cover", // Ensures the image covers the area while maintaining aspect ratio
                borderRadius: "8px",
              }}
            />
          </div>

          <div style={styles.container}>
            <h1 style={styles.heading}>About Us</h1>

            <p style={styles.paragraph}>
              Welcome to our online brand! We are dedicated to providing great
              services and high-quality products to our customers.
            </p>
            <p style={styles.paragraph}>
              At our company, we understand that shopping is a personal
              experience. That's why we offer personal shopping services
              strictly on request. Our goal is to cater to your unique needs and
              preferences.
            </p>
            <h2 style={styles.heading}>Our Services</h2>
            <ul style={styles.servicesList}>
              <li style={styles.listItem}>🌟 High-Quality Products</li>
              <li style={styles.listItem}>🌟 Excellent Customer Service</li>
              <li style={styles.listItem}>🌟 Personal Shopping on Request</li>
              <li style={styles.listItem}>
                🌟 Custom Solutions for Unique Needs
              </li>
            </ul>
            <p style={styles.paragraph}>
              Thank you for choosing us! We look forward to serving you and
              making your shopping experience delightful.
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default AboutUs;
