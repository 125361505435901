import React, { useContext, useState } from 'react';
import Title from '../Components/Title/Title';
import CartTotal from '../Components/CartTotal/CartTotal';
import { assets } from '../Components/Assets/assets';
import { ShopContext } from '../Context/ShopContextProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import './CSS/PlaceOrder.css';

const PlaceOrder = () => {
    const [method, setMethod] = useState('cod');
    const { navigate, backendUrl, token, cartItems, setCartItems, getCartAmount, delivery_fee, products } = useContext(ShopContext);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        street: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        phone: ''
    });
    const [loading, setLoading] = useState(false); // Loading state

    const formatCurrency = (amount) => {
        const formattedAmount = parseFloat(amount).toLocaleString('en-NG', { minimumFractionDigits: 2 });
        return `${formData.country === 'Nigeria' ? '₦' : '$'}${formattedAmount}`;
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.target;
        setFormData(data => ({ ...data, [name]: value }));
    };

    const handleCityChange = (event) => {
        setFormData(data => ({ ...data, city: event.target.value }));
    };

    const handleBackToCart = () => {
        navigate('/cart'); // Update with your cart route
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        setLoading(true); // Set loading to true on form submit
        try {
            let orderItems = [];

            for (const items in cartItems) {
                for (const item in cartItems[items]) {
                    if (cartItems[items][item] > 0) {
                        const itemInfo = structuredClone(products.find(product => product._id === items));
                        if (itemInfo) {
                            itemInfo.size = item;
                            itemInfo.quantity = cartItems[items][item];
                            orderItems.push(itemInfo);
                        }
                    }
                }
            }

            let orderData = {
                address: formData,
                items: orderItems,
                amount: getCartAmount() + delivery_fee,
                currency: formData.country === 'Nigeria' ? 'NGN' : 'USD'
            };

            switch (method) {
                case 'cod':
                    const response = await axios.post(`${backendUrl}/api/order/place`, orderData, { headers: { token } });
                    if (response.data.success) {
                        setCartItems({});
                        navigate('/orders');
                    } else {
                        toast.error(response.data.message);
                    }
                    break;

                case 'stripe':
                    const responseStripe = await axios.post(`${backendUrl}/api/order/stripe`, orderData, { headers: { token } });
                    if (responseStripe.data.success) {
                        const { session_url } = responseStripe.data;
                        window.location.replace(session_url);
                    } else {
                        toast.error(responseStripe.data.message);
                    }
                    break;

                case 'paystack':
                    // Paystack logic will go here
                    break;

                default:
                    break;
            }

        } catch (error) {
            console.log(error);
            toast.error(error.message);
        } finally {
            setLoading(false); // Reset loading state after processing
        }
    };

    return (
        <div className='place-order-container'>
            <form onSubmit={onSubmitHandler}>
                {/* Left Side: Shipping Details */}
                <div className='form-section'>
                    <div className='form-title'>
                        <Title text1={'DELIVERY'} text2={'INFORMATION'} />
                    </div>
                    <div className='form-inputs'>
                        <div className='form-row'>
                            <input required onChange={onChangeHandler} name='firstName' value={formData.firstName} className='input-field' type="text" placeholder='First name' />
                            <input required onChange={onChangeHandler} name='lastName' value={formData.lastName} className='input-field' type="text" placeholder='Last name' />
                        </div>
                        <input required onChange={onChangeHandler} name='email' value={formData.email} className='input-field' type="email" placeholder='Email address' />
                        <input required onChange={onChangeHandler} name='street' value={formData.street} className='input-field street-input' type="text" placeholder='Street' />
                        <div className='form-row'>
                            <input required onChange={onChangeHandler} name='city' value={formData.city} className='input-field' type="text" placeholder='City' />
                            <input onChange={onChangeHandler} name='state' value={formData.state} className='input-field' type="text" placeholder='State' />
                        </div>
                        <div className='form-row'>
                            <input required onChange={onChangeHandler} name='zipcode' value={formData.zipcode} className='input-field' type="number" placeholder='Zipcode' />
                            <input required onChange={onChangeHandler} name='country' value={formData.country} className='input-field' type="text" placeholder='Country' />
                        </div>
                        <input required onChange={onChangeHandler} name='phone' value={formData.phone} className='input-field' type="number" placeholder='Phone' />
                    </div>
                </div>

                {/* Right Side: Payment Information */}
                <div className='form-section'>
                    <div className='cart-total-container'>
                        <CartTotal 
                            subtotal={formatCurrency(getCartAmount())}
                            shipping={formatCurrency(delivery_fee)}
                            total={formatCurrency(getCartAmount() + delivery_fee)}
                            currency={formData.country === 'Nigeria' ? 'NGN' : 'USD'}
                        />
                    </div>

                    <div className='payment-method'>
                        <Title text1={'PAYMENT'} text2={'METHOD'} />
                        <div className='payment-options'>
                            <div onClick={() => setMethod('paystack')} className='payment-option'>
                                <input type="radio" name="payment" checked={method === 'paystack'} onChange={() => setMethod('paystack')} />
                                <img src={assets.paystack_logo} alt="Paystack" className='payment-logo' />
                            </div>
                            <div onClick={() => setMethod('stripe')} className='payment-option'>
                                <input type="radio" name="payment" checked={method === 'stripe'} onChange={() => setMethod('stripe')} />
                                <img src={assets.stripe_logo} alt="Stripe" className='payment-logo' />
                            </div>
                            <div onClick={() => setMethod('cod')} className='payment-option'>
                                <input type="radio" name="payment" checked={method === 'cod'} onChange={() => setMethod('cod')} />
                                <p className='payment-text'>Cash on Delivery</p>
                                {method === 'cod' && (
                                    <select onChange={handleCityChange} className='cod-city-select'>
                                        <option value="">Select your city</option>
                                        <option value="warri">Warri</option>
                                        <option value="ajah">Ajah</option>
                                        <option value="chevron">Chevron</option>
                                        <option value="benin">Benin</option>
                                        <option value="wuse">Wuse</option>
                                    </select>
                                )}
                            </div>
                        </div>

                        {/* Buttons */}
                        <div className='button-group'>
                            <button type='submit' className='btn btn-primary' disabled={loading}>
                                {loading ? 'Processing...' : 'PLACE ORDER'}
                            </button>
                            <button type='button' className='btn btn-secondary' onClick={handleBackToCart}>
                                BACK TO CART
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default PlaceOrder;
