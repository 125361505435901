import React, { useState } from "react";
import "./CSS/LoginSignup.css";
import { backend_url } from "../App";
import axios from "axios";

const LoginSignup = () => {
  const API_URL = backend_url + "/user";
  
  const [state, setState] = useState("Login");
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    password2:"",
  });

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Debugging - Check if login function is triggered and form data is correct
  const login = async () => {
    console.log("Login clicked. Form data:", formData);

    let dataObj;
    try {
      const response = await axios.post(
        `${API_URL}/login`,
        formData, // This will automatically be converted to JSON
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

        if (response.data.status == false) {
          throw new Error(`HTTP error! Status: ${response.data.message}`);
        }

        // const data = await response.json();
        dataObj = response.data;
      console.log("Login response:", dataObj);

      // Check if login was successful
      if (dataObj.status) {
        localStorage.setItem("auth-token", dataObj.payload);
         alert(
           dataObj.message
         );
        window.location.replace("/");
      } else {
        alert(dataObj.errors || "Login failed. Please check your credentials.");
      }
    } catch (error) {
      // Handle error from axios response
      if (error.response) {
        // The request was made, and the server responded with a status code outside of the 2xx range
        console.error("Error response:", error.response);
        const errorMessage =
          error.response.data?.message || "An error occurred during login.";
        alert(errorMessage);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("Error request:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        // Something else happened during the setup of the request
        console.error("Error message:", error.message);
        alert("Login failed. Please try again.");
      }
    }
  };
  const signup = async () => {
    // Signup function remains the same with similar debugging
    let dataObj;
    try {
     const response = await axios.post(
       `${API_URL}/signup`,
       formData, // Axios automatically serializes formData to JSON
       {
         headers: {
           Accept: "application/json",
           "Content-Type": "application/json",
         },
       }
     );
      

      if (response.data.status == false) {
        throw new Error(`HTTP error! Status: ${response.data.message}`);
      }

      // const data = await response.json();
      dataObj = response.data;
      console.log("Signup response:", dataObj);

      if (dataObj.status) {
        localStorage.setItem("auth-token", dataObj.token);
        window.location.replace("/login");
      } else {
        alert(dataObj.errors || "Signup failed. Please try again.");
      }
    } catch (error) {
      // Handle error from axios response
      if (error.response) {
        // The request was made, and the server responded with a status code outside of the 2xx range
        console.error("Error response:", error.response);
        const errorMessage =
          error.response.data?.message || "An error occurred during login.";
        alert(errorMessage);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("Error request:", error.request);
        alert("No response from the server. Please try again later.");
      } else {
        // Something else happened during the setup of the request
        console.log("Error message:", error);
        alert("Signup failed. Please try again.");
      }
    }
  };
  return (
    <div className="loginsignup">
      <div className="loginsignup-container">
        <h1>{state}</h1>
        <div className="loginsignup-fields">
          <input
            type="email"
            placeholder="Email address"
            name="email"
            value={formData.email}
            onChange={changeHandler}
          />
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={formData.password}
            onChange={changeHandler}
          />
          {state === "Sign Up" && (
            <>
              <input
                type="password"
                placeholder="Confirm Password"
                name="password2"
                value={formData.password2}
                onChange={changeHandler}
              />
              <input
                type="text"
                placeholder="Your name"
                name="username"
                value={formData.username}
                onChange={changeHandler}
              />
            </>
          )}
        </div>

        <button onClick={() => (state === "Login" ? login() : signup())}>
          Continue
        </button>

        {state === "Login" ? (
          <p className="loginsignup-login">
            Create an account?{" "}
            <span onClick={() => setState("Sign Up")}>Click here</span>
          </p>
        ) : (
          <p className="loginsignup-login">
            Already have an account?{" "}
            <span onClick={() => setState("Login")}>Login here</span>
          </p>
        )}

        <div className="loginsignup-agree">
          <input type="checkbox" />
          <p>By continuing, I agree to the terms of use & privacy policy.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
