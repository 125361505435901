import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./AddAccount.css";

const AddAccount = () => {
    const [virtualAccount, setVirtualAccount] = useState('');  // This state can store the created account info
    const [loading, setLoading] = useState(false);  // Loading state to show a spinner or disable button during API call
    const navigate = useNavigate();

    // Function to handle the creation of a virtual account
    const handleCreateVirtualAccount = async () => {
        setLoading(true);  // Show loader or disable button while creating account
        try {
            const response = await fetch('https://api.paystack.co/dedicated_account', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`, 
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    customerId: '481193',  // Replace with actual dynamic customer ID
                    preferredBank: 'wema-bank',  // Optional: Customize or allow user to select bank
                }),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            setVirtualAccount(data.account_number);  // Store created account details in state
            alert(`Virtual account created successfully: ${data.account_number}`);

            navigate('/wallet');  // Redirect to the wallet page on success
        } catch (error) {
            console.error('Error creating virtual account:', error);
            alert('Error creating virtual account. Please try again.');
        } finally {
            setLoading(false);  // Reset the loading state
        }
    };

    // Function to handle cancellation (navigating back to wallet)
    const handleCancel = () => {
        navigate('/wallet');
    };

    return (
        <div className="add-account-container">
            <h2>Add New Virtual Account</h2>
            <button onClick={handleCreateVirtualAccount} disabled={loading}>
                {loading ? 'Creating Account...' : 'Add New Virtual Account'}
            </button>
            <button onClick={handleCancel} disabled={loading}>
                Cancel
            </button>

            {/* Optionally display virtual account details after creation */}
            {virtualAccount && (
                <div className="account-details">
                    <h3>Virtual Account Created</h3>
                    <p>Account Number: {virtualAccount}</p>
                </div>
            )}
        </div>
    );
};

export default AddAccount;
