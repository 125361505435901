import axios from 'axios';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for unique request ID

const vtpassService = {
  baseURL: 'https://sandbox.vtpass.com/api/', // Base URL for VTpass API

  // Service IDs for different providers (You can replace these with actual IDs from VTpass)
  serviceIDs: {
    airtime: {
      mtn: 'mtn',        // MTN service ID
      glo: 'glo',        // GLO service ID
      airtel: 'airtel',  // Airtel service ID
      etisalat: 'etisalat' // 9mobile service ID
    },
    data: {
      mtn: 'mtn-data',   // MTN data service ID
      glo: 'glo-data',   // GLO data service ID
      airtel: 'airtel-data', // Airtel data service ID
      etisalat: 'etisalat-data' // 9mobile data service ID
    },
    cable: {
      dstv: 'dstv', // DSTV service ID
      gotv: 'gotv', // GOTV service ID
      startimes: 'startimes',
      showmax: 'showmax'
    },
    electricity: {
      eko: 'eko-electric', // Eko Electricity service ID
      ikeja: 'ikeja-electric', // Ikeja Electricity service ID
      abuja: 'abuja-electric',
      ibadan: 'ibadan-electric',
      enugu: 'enugu-electric',
      kano: 'kano-electric',
      jos: 'jos-electric',
      portharcourt: 'portharourt-electric'
    },
    internet: {
      spectranet: 'spectranet',
      smile: 'smile',
      ipnx: 'ipnx'
    }

  },

  // Function to initiate airtime recharge
  rechargeAirtime: async (networkProvider, phoneNumber, amount) => {
    try {
      const serviceID = vtpassService.serviceIDs.airtime[networkProvider];
      const response = await axios.post(`${vtpassService.baseURL}pay`, {
        serviceID, // Service ID for the correct network provider
        phone: phoneNumber,
        amount,
        request_id: uuidv4(), // Use UUID for unique request ID
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.VT_PASS_ACCESS_TOKEN}`,  // Replace with your actual access token
          'Content-Type': 'application/json',
        }
      });
      return response.data; // Return response data on success
    } catch (error) {
      console.error("Airtime Recharge Error:", error.response?.data || error);
      throw new Error("Failed to recharge airtime. Please try again.");// Re-throw the error for the frontend to handle
    }
  },

  // Function to initiate data subscription payment
  subscribeData: async (networkProvider, phoneNumber, amount, dataPlan) => {
    try {
      const serviceID = vtpassService.serviceIDs.data[networkProvider];
      const response = await axios.post(`${vtpassService.baseURL}pay`, {
        serviceID, // Service ID for data subscription
        phone: phoneNumber,
        amount,
        variation_code: dataPlan, // Variation code for the specific data plan
        request_id: uuidv4(),  // Unique request ID
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.VT_PASS_ACCESS_TOKEN}`,  // Replace with your actual access token
          'Content-Type': 'application/json',
        }
      });
      return response.data; // Return response data on success
    } catch (error) {
      console.error("Data Subscription Error:", error.response?.data || error);
      throw new Error("Failed to subscribe to data. Please try again.");
    }
  },

  // Function to initiate cable subscription payment
  subscribeCable: async (cableProvider, packageVariation, amount, accountNumber) => {
    try {
      const serviceID = vtpassService.serviceIDs.cable[cableProvider];
      const response = await axios.post(`${vtpassService.baseURL}pay`, {
        serviceID, // Service ID for cable provider
        variation_code: packageVariation, // Variation code for the selected package
        billersCode: accountNumber, // Account number (e.g., smart card number)
        amount,
        request_id: uuidv4(), // Unique request ID
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.VT_PASS_ACCESS_TOKEN}`,  // Replace with your actual access token
          'Content-Type': 'application/json',
        }
      });
      return response.data; // Return response data on success
    } catch (error) {
      console.error("Cable Subscription Error:", error.response?.data || error);
      throw new Error("Failed to subscribe to cable. Please try again.");
    }
  },

  // Function to initiate electricity bill payment
  payElectricityBill: async (electricityProvider, accountNumber, amount) => {
    try {
      const serviceID = vtpassService.serviceIDs.electricity[electricityProvider];
      const response = await axios.post(`${vtpassService.baseURL}pay`, {
        serviceID, // Service ID for electricity provider
        billersCode: accountNumber, // Account number or meter number
        amount,
        request_id: uuidv4(),  // Unique request ID
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.VT_PASS_ACCESS_TOKEN}`, // Replace with your actual access token
          'Content-Type': 'application/json',
        }
      });
      return response.data; // Return response data on success
    } catch (error) {
      console.error("Electricity Bill Payment Error:", error.response?.data || error);
      throw new Error("Failed to pay electricity bill. Please try again.");
    }
  },
};

// Example of usage:

const testVTpass = async () => {
  try {
    // Airtime recharge example
    const airtimeResponse = await vtpassService.rechargeAirtime('mtn', '08012345678', 500);
    console.log("Airtime Recharge Success:", airtimeResponse);

    // Data subscription example
    const dataResponse = await vtpassService.subscribeData('mtn', '08012345678', 1000, 'daily');
    console.log("Data Subscription Success:", dataResponse);

    // Cable subscription example (DSTV Yanga package)
    const cableResponse = await vtpassService.subscribeCable('dstv', 'dstv-yanga', 2565, '1234567890');
    console.log("Cable Subscription Success:", cableResponse);

    // Electricity bill payment example
    const electricityResponse = await vtpassService.payElectricityBill('eko', '1234567890', 5000);
    console.log("Electricity Bill Payment Success:", electricityResponse);

  } catch (error) {
    console.error("Error occurred:", error);
  }
};

// Uncomment to test the functions
 testVTpass();

export default vtpassService;
