
import React from 'react';
import './CSS/Modal.css';

const RefundModal = ({ isOpen, onClose, transaction, onRequestRefund }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Request Refund</h2>
                <p>Are you sure you want to request a refund for this transaction?</p>
                <p>Transaction Amount: ₦{transaction.amount.toFixed(2)}</p>
                <p>Description: {transaction.description}</p>
                <div className="modal-actions">
                    <button onClick={onClose}>Cancel</button>
                    <button onClick={() => onRequestRefund(transaction)}>Confirm Refund</button>
                </div>
            </div>
        </div>
    );
};

export default RefundModal;
