import React, { useEffect, useState } from "react";
import '../../src/Pages/CSS/Profile.css';

const Profile = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State for handling errors
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    address: '',
    phone: ''
  });

  const apiBaseURL = "http://127.0.0.1:4000";

  useEffect(() => {
    // Fetch user info from the backend
    fetch(`${apiBaseURL}/user/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch user info');
        }
        return res.json();
      })
      .then((data) => {
        setUserInfo(data);
        setFormData({
          name: data?.name || '',
          email: data?.email || '',
          address: data?.address || '',
          phone: data?.phone || ''
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user info:", error);
        alert("Failed to fetch user info. Please try again later.");
        setLoading(false);
      });
  }, [apiBaseURL]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission to update user info
    fetch(`${apiBaseURL}/user/update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to update user info');
        }
        return res.json();
      })
      .then((data) => {
        setUserInfo(data);
        setIsEditing(false);
        alert("User info updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating user info:", error);
        setError("Failed to update user info. Please try again."); // Set error state
      });
  };

  if (loading) {
    return (
      <div className="skeleton-loader">
        <p>Loading user information...</p>
        {/* Skeleton Loader */}
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h2>User Profile</h2>
      {error && <p className="error">{error}</p>} {/* Display error if any */}
      {userInfo ? (
        <div className="profile-info">
          {isEditing ? (
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label>Address:</label>
                <input type="text" name="address" value={formData.address} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label>Phone:</label>
                <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
              </div>
              <button type="submit">Save</button>
              <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
            </form>
          ) : (
            <div>
              <p>Name: {userInfo.name}</p>
              <p>Email: {userInfo.email}</p>
              <p>Address: {userInfo.address}</p>
              <p>Phone: {userInfo.phone}</p>
              <button onClick={handleEdit}>Edit Profile</button>
            </div>
          )}
        </div>
      ) : (
        <p>No user info found. Please try again later.</p>
      )}
    </div>
  );
};

export default Profile;
