import React, { useState, useEffect } from 'react';
import './Notification.css'; // Import your CSS file

const NotificationPage = () => {
    const [notifications, setNotifications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showRead, setShowRead] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationsPerPage, setNotificationsPerPage] = useState(25); // Default 25 per page
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [selectedNotifications, setSelectedNotifications] = useState([]);

    // Mock notifications data (60 notifications)
    const mockNotifications = Array.from({ length: 60 }, (_, index) => ({
        id: index + 1,
        message: `Notification message #${index + 1}`,
        isRead: index % 2 === 0, // Mark even notifications as read
        date: new Date(Date.now() - index * 1000000), // Different time for each notification
    }));

    useEffect(() => {
        setNotifications(mockNotifications);
    }, []);

    const handleNotificationClick = (id) => {
        setNotifications((prev) =>
            prev.map((notif) => {
                if (notif.id === id) {
                    setSelectedNotification(notif); // Set selected notification
                    return { ...notif, isRead: true };
                }
                return notif;
            })
        );
    };

    const handleSelectNotification = (id) => {
        setSelectedNotifications((prev) => {
            if (prev.includes(id)) {
                return prev.filter((notifId) => notifId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectedNotifications.length === notifications.length) {
            setSelectedNotifications([]);
        } else {
            setSelectedNotifications(notifications.map((notif) => notif.id));
        }
    };

    const handleMarkAsRead = () => {
        setNotifications((prev) =>
            prev.map((notif) =>
                selectedNotifications.includes(notif.id)
                    ? { ...notif, isRead: true }
                    : notif
            )
        );
        setSelectedNotifications([]);
    };

    const handleMarkAsUnread = () => {
        setNotifications((prev) =>
            prev.map((notif) =>
                selectedNotifications.includes(notif.id)
                    ? { ...notif, isRead: false }
                    : notif
            )
        );
        setSelectedNotifications([]);
    };

    const handleDeleteNotifications = () => {
        setNotifications((prev) =>
            prev.filter((notif) => !selectedNotifications.includes(notif.id))
        );
        setSelectedNotifications([]);
    };

    const filteredNotifications = notifications.filter((notif) =>
        (showRead || !notif.isRead) &&
        notif.message.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastNotification = currentPage * notificationsPerPage;
    const indexOfFirstNotification = indexOfLastNotification - notificationsPerPage;
    const currentNotifications = filteredNotifications.slice(indexOfFirstNotification, indexOfLastNotification);

    const totalPages = Math.ceil(filteredNotifications.length / notificationsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setSelectedNotification(null); // Reset selection on page change
    };

    const handlePerPageChange = (e) => {
        setNotificationsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page after changing per-page count
    };

    return (
        <div className="notification-page">
            {/* Side Menu */}
            <div className="side-menu">
                <ul>
                    <li>My Account</li>
                    <li>Inbox</li>
                    <li>Orders</li>
                    <li>Saved Items</li>
                    <li>Pending Review</li>
                    <li>Settings</li>
                </ul>
            </div>

            {/* Main Notification Content */}
            <div className="notification-content">
                <h1 className="notification-header">Inbox Messages</h1>

                {/* Search and Filters */}
                <div className="notification-filters">
                    <input
                        type="text"
                        placeholder="Search inbox..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                                        <div className="sort-by">
                        <label>Sort by:</label>
                        <select>
                            <option>Latest</option>
                            <option>Oldest</option>
                        </select>
                    </div>

                 
                </div>

                {/* Notification List */}
                {!selectedNotification ? (
                    <>
                        <div className="select-all">
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={
                                    selectedNotifications.length === notifications.length
                                }
                            />
<div className="mark-options">
    <label>Select:</label>
    <select onChange={(e) => {
        const action = e.target.value;
        if (action === 'markRead') {
            handleMarkAsRead();
        } else if (action === 'markUnread') {
            handleMarkAsUnread();
        } else if (action === 'delete') {
            handleDeleteNotifications();
        }
    }}>
        <option value="">Select Action</option>
        <option value="markRead">Mark as Read</option>
        <option value="markUnread">Mark as Unread</option>
        <option value="delete">Delete</option>
    </select>
</div>


                        </div>

                        <div className="notification-list-container">
                            {currentNotifications.length > 0 ? (
                                currentNotifications.map((notif) => (
                                    <div
                                        key={notif.id}
                                        className={`notification-item ${notif.isRead ? 'read' : 'unread'}`}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedNotifications.includes(notif.id)}
                                            onChange={() => handleSelectNotification(notif.id)}
                                        />
                                        <div
                                            className="notification-snippet"
                                            onClick={() => handleNotificationClick(notif.id)}
                                        >
                                            {notif.message} {notif.isRead ? '' : '(New)'}
                                        </div>
                                        <div className="notification-date">
                                            {notif.date.toLocaleString()}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p className="no-notifications">No notifications found</p>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="notification-detail">
                        <button onClick={() => setSelectedNotification(null)} className="back-arrow">
                            ← Back to Inbox
                        </button>
                        <h2>Notification Detail</h2>
                        <p>{selectedNotification.message}</p>
                    </div>
                )}

                {/* Pagination */}
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <div
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={`pagination-item ${currentPage === index + 1 ? 'active' : ''}`}
                        >
                            {index + 1}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NotificationPage;
